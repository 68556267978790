@import "/styles/tools/";

.insightCardListing {
  @include vertical-spacing;
  margin-top: rem(40);
  border-top: rem(1) solid token("general-divide-light");
  border-bottom: rem(1) solid token("general-divide-light");

  &__inner {
    @include container;
  }

  &__top {
    padding-bottom: rem(40);

    @include above(md) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  &__heading {
    @include typeHeadline(24);
    color: token("secondary-on-general-light");
    margin-bottom: rem(24);

    @include above(md) {
      @include typeHeadline(32);
      margin-bottom: rem(0);
    }
  }

  &__intro {
    @include typeHeadline(16);
    margin-top: rem(8);
    color: token("primary-on-general-light");

    @include above(md) {
      margin-top: rem(16);
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
    gap: rem(24);

    @include above(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__cta {
    @include above(md) {
      margin-left: auto;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: rem(16);

    // This is a middle ground between the mobile and desktop layout to meet design requirements
    @media (min-width: 640px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-areas:
        "Large-Card Large-Card"
        "Small-Card-1 Small-Card-2"
        "Small-Card-3 Small-Card-4";
    }

    @include above("mdlg") {
      grid-template-columns: auto repeat(2, rem(250));
      grid-template-areas:
        "Large-Card Small-Card-1 Small-Card-2"
        "Large-Card Small-Card-3 Small-Card-4";
      grid-template-rows: repeat(2, 1fr);
      gap: rem(24);
      grid-auto-flow: row;
    }

    @include above("lg") {
      grid-template-columns: 2fr repeat(2, 1fr);
    }
  }

  .largecard {
    @media (min-width: 640px) {
      grid-area: Large-Card;
    }
  }

  .card1 {
    @media (min-width: 640px) {
      grid-area: Small-Card-1;
    }
  }

  .card2 {
    @media (min-width: 640px) {
      grid-area: Small-Card-2;
    }
  }

  .card3 {
    @media (min-width: 640px) {
      grid-area: Small-Card-3;
    }
  }

  .card4 {
    @media (min-width: 640px) {
      grid-area: Small-Card-4;
    }
  }

  & + [class^="ComponentMultiCardCallout_multiCardCallout__"] {
    border-top: 0 !important;
  }
}
